/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "~ngx-toastr/toastr.css";

.hoverwell:hover {
  background-color: #485563;
  cursor: pointer;
}

.container {
  padding-top: 10px;
}

.pointable {
  cursor: pointer;
}

button {
  color: #555;
}

[hidden] {
  display: none !important;
}

.toast-success {
  background-color: #51a351;
}
.toast-error {
  background-color: #bd362f;
}
.toast-info {
  background-color: #2f96b4;
}
.toast-warning {
  background-color: #f89406;
}

mat-icon {
  margin-right: 10px;
}

._8orders-color {
  color: lightslategray;
}
.color_menu {
  color: rgb(255, 254, 251) !important;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.full-screen {
  height: 100%;
  overflow: auto;
}

.dx-designer {
  height: 88vh !important;
}

/*Global Style*/
em {
  float: right;
  color: #e05c65;
  padding-left: 10px;
}
.error input,
.error select,
.error textarea {
  background-color: #e3c3c5;
}
.error ::-webkit-input-placeholder {
  color: #999;
}
.error :-moz-placeholder {
  color: #999;
}
.error ::-moz-placeholder {
  color: #999;
}
.error :ms-input-placeholder {
  color: #999;
}

/*Cards */

.card-header {
  background-color: #aecaf3e8;
}

dx-button:hover {
  box-shadow: 1px 1px 3px 3px #aecaf3e8;
  border-radius: 3px;
  transform: translateY(-3px);
}
.submit-button {
  text-align: center;
  top: 50%;
  left: 50%;
  position: relative;
}
.card {
  box-shadow: 1px 1px 2px 2px rgb(5 9 46);
  margin: 17px;
}

.card-header {
  background-color: #9dc0f3e8;
}

.description {
  font-style: oblique;
  font-family: cursive;
  font-size: x-large;
}

label {
  font-size: larger;
  font-style: oblique;
  font-weight: 400;
}

.change-visibility {
  text-align: center;
  top: 50%;
  left: 50%;
  position: relative;
}
